@media (max-width: 1145px) {
    .dashboard {
        flex-direction: column;
        align-items: center;
        gap: 48px;

        .buffs {
            max-width: 100%;

            &__title {
                font-size: 28px;
                text-align: center;
            }

            &__wrapper {
                flex-direction: row;
                justify-content: center;
            }
        }
    }
}

@media (max-width: 1024px) {
    .footer {
        &__content {
            padding: 32px;
        }
    }
}


@media (max-width: 767px) {
    html {

        body {
            .popup {
                padding: 0px 24px;
            }
            .modal {
                padding: 24px;
                border-radius: 15px;
                gap: 24px;

                .form {
                    gap: 16px;
                }
            }
            .pannel__ref-wrapper {
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
            .navbar {
                padding: 0;
                border: none;
                &__logo {
                    display: none;
                }

                &__logo-mob {
                    width: 24px;
                    height: 24px;
                    display: block;
                }

                &__burger {
                    display: block;
                }
            }

            #dashboard-container {
                padding: 0;
            }

            .dashboard {
                .pannel {
                    max-width: 100%;
                    padding: 0 24px;

                    .pannel__info-row {
                        .pannel__info-content {
                            flex-basis: 30%;
                        }

                        .pannel__info-address {
                            width: 100%;
                            justify-content: space-between;
                        }
                    }

                    .pannel__info-part:nth-child(1) {
                        .pannel__info-row:nth-child(1) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }

                    &__ref {
                        &-title {
                            text-align: center;
                        }

                       

                        &-copy {
                            width: 100%;
                            justify-content: center;

                            svg {
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }

                .form {
                    padding: 0 24px;

                    &-wrapper {
                        padding: 28px 0px;
                    }
                }

                .buffs {
                    .buffs__title {
                        margin: 0 auto;
                    }

                    &__wrapper {
                        overflow-x: scroll;
                        justify-content: flex-start;
                        padding: 0 24px;

                        .buff {
                            border-radius: 16px;
                            position: relative;

                            min-width: 232px;
                            min-height: 232px;

                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                translate: -50% -50%;
                                min-width: 150px;

                                transition: filter .2s ease-in-out;
                            }

                            &:hover {
                                img {
                                    filter: blur(5px)
                                }
                                .buff__hidden {
                                    margin: 0;
                                    min-width: 200px;
                                    font-size: 24px !important;
                                    line-height: 28px !important;
                                    animation: fadeIn .2s;
                                    transition: font-size .1s ease-in-out;
                                    text-align: center;
                                    translate: 0px 0px;
                                }
                            }
                        }

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }
            }

            .stake,
            .nfts {
                &__title {
                    font-size: 28px;
                }
            }

            .nfts {
                img {
                    height: 370px;
                    object-fit: cover;
                }
            }

            .stake {
                padding: 120px 0px;
            }

            .footer {
                background: radial-gradient(100% 35% at 50% 100%, rgba(1, 65, 214, 0.5), rgb(0, 0, 0) 100%);

                &__content {
                    border: none;
                    padding: 0;
                    background: none;
                    box-shadow: none;
                    flex-direction: column;
                    gap: 48px;
                }

                &__col:nth-child(1) {
                    order: 1;
                }

                &__col:nth-child(2) {
                    order: 3;
                }

                &__col:nth-child(3) {
                    order: 2;
                }

                &__col:nth-child(4) {
                    order: 4;

                    .footer__sub {
                        color: $white;
                    }
                }

                &__links {
                    flex-direction: row;

                    a {
                        width: 32px;
                        height: 32px;
                        border: 1px solid $gray;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        font-size: 0;
                        gap: 0px;
                        background-color: rgba(255, 255, 255, .08);

                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                &__rights {
                    display: none;
                }

                &__rights_mob {
                    display: block;
                    font-size: 12px;
                }
            }
        }
    }
}