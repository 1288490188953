.stake {
    padding: 64px 0;

    &__title {
        text-align: center;
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
    }

    &__sub {
        margin-top: 32px;
        color: rgb(131, 131, 131);
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
    }

    .avaliablesoon {
        margin: 0 auto;
        margin-top: 32px;
    }
}