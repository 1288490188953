.buffs {
    max-width: 256px;
    width: 100%;

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    &__wrapper {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.buff {
    opacity: .5;
    min-height: 128px;
    cursor: pointer;
    padding: 23px;
    box-sizing: border-box;
    border: 1px solid rgb(41, 41, 41);
    border-radius: 24px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgb(31, 31, 31), inset 0px -1px 0px 0px rgb(31, 31, 31), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12);
    background: linear-gradient(180.00deg, rgb(25, 25, 25), rgb(12, 12, 12) 100%);
    background-size: 200% 200%;
    background-position: bottom center;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-size .3s ease-in-out, opacity .3s ease-in-out;

    &__hidden {
        max-width: 150px;
        font-size: 0;
        transition: font-size 0.25s ease-in-out, opacity 0s, margin 0.5s ease-in-out;
        opacity: 0;
        translate: 10px 0px;
    }

    &:hover {
        opacity: 1;
        padding: 23px 16px;
        box-sizing: border-box;
        border: 1px solid rgb(41, 41, 41);
        border-radius: 24px;
        background-position: top center;
        background-size: 100% 100%;

    }

    img {
        width: 80px;
        height: auto;
    }
}

.buff:hover {
    .buff__hidden {
        font-size: 13px !important;
        font-weight: 400;
        line-height: 18px;
        opacity: 1;
        animation: fadeIn .5s linear;
        // transition: font-size 1s ease-in-out, opacity .4s;
    }
}