.form-wrapper {
    padding: 24px;
    max-width: 448px;
    width: 100%;
    border: 1px solid rgb(41, 41, 41);
    border-radius: 24px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgb(31, 31, 31), inset 0px -1px 0px 0px rgb(31, 31, 31), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12);
    background: rgb(15, 15, 15);

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


    display: flex;
    flex-direction: column;
    align-items: center;

    &__mint {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        opacity: 0.5;
    }

    &__btn {
        padding: 8px;
        max-width: 190px;
        width: 100%;
        border-radius: 120px;
        box-shadow: inset 0px -1px 0px 0px rgb(225, 225, 225), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgb(225, 225, 225);
        background: rgb(255, 255, 255);

        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: black;

        border: 1px solid transparent;
        
        transition: .2s ease-in-out;

        &:hover {
            background-color: transparent;
            box-shadow: none;
            border: 1px solid white;
            color: white;
        }
    }
}