.popup {
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    backdrop-filter: blur(5px);

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

}

.modal {
    padding: 32px;
    box-sizing: border-box;
    border: 1px solid rgb(41, 41, 41);
    border-radius: 32px;

    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgb(31, 31, 31), inset 0px -1px 0px 0px rgb(31, 31, 31), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12);
    background: rgb(15, 15, 15);

    display: flex;
    flex-direction: column;
    gap: 48px;

    max-width: 424px;
    width: 100%;

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
    }

    &__close {
        border-radius: 40px;
        background: rgb(29, 29, 29);
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button {
        margin: 0 auto;
        padding: 8px 0;
        border-radius: 120px;
        box-shadow: inset 0px -1px 0px 0px rgb(225, 225, 225), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgb(225, 225, 225);
        background: rgb(255, 255, 255);

        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        max-width: 140px;
        width: 100%;

        border: 1px solid transparent;

        transition: .2s ease-in-out;

        color: black;

        &:hover {
            background-color: transparent;
            color: white;
            box-shadow: none;
            border: 1px solid white;
        }

        &:disabled {
            opacity: .2;

            &:hover {
                background: rgb(255, 255, 255);
                color: black;
                border: 1px solid transparent;
                box-shadow: inset 0px -1px 0px 0px rgb(225, 225, 225), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgb(225, 225, 225);

            }
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__control {
        width: 100%;
    }

    input {
        border: none;
        background-color: transparent;
        color: white;

        &::placeholder {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: rgb(167, 167, 167);
        }
    }

    input.form__content {
        &::placeholder {
            color: white;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }
    }

    &__input {
        cursor: pointer;
        margin-top: 8px;
        box-sizing: border-box;
        border: 1px solid rgb(29, 29, 29);
        border-radius: 8px;

        background: rgb(15, 15, 15);

        display: flex;
        justify-content: space-between;
        align-items: center;


        padding: 8px;
        background-color: transparent;
        width: 100%;

        div.mobInput {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        div.form__content {
            width: 100%;
            svg:nth-child(3) {
                margin-left: auto;
            }
        }
    }

    a {
        &:hover {
            opacity: 1;
        }
    }

    &__placeholder {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        color: rgb(167, 167, 167);
    }

    &__content {
        display: flex;
        align-items: center;
        gap: 12px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }

    &__sub {
        margin-top: 8px;
        color: rgb(131, 131, 131);
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
    }

    &__step {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        display: flex;
        align-items: center;

        span {
            margin-left: 10px;
            font-size: 11px;
            color: rgb(163, 45, 45);
        }
    }
}

.modal_success {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .modal__message {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

    .modal__text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}