//Fonts


// colors
$white: #fff;
$black: #000000;
$bg: #19143B;
$yellow: #FEFE01;
$stormy: #323334;
$smoke: #E6E6F4;
$opal: #8DBAB5;
$seafoam: #CCE0DE; 
$sage: #EEF1EC; 
$timber: #BBA28B; 
$oyster: #FFD6AD; 
$cloudy: #FFD6AD; 

$purple: #5C53E9;
$blue: #5031D3;
$lightpurple: #7764F5;
$gray: #A7A7A7;
$mgray: #838383;
$dgray: #666666;

$red: #B72E61;
$dark: #0D092B;

// media
$media_xxxl: 'max-width: 1400px';
$media_xxl: 'max-width: 1300px';
$media_xl: 'max-width: 1200px';
$media_lg: 'max-width: 1100px';
$media_lg2: 'max-width: 1000px';
$media_lg3: 'max-width: 900px';
$media_md: 'max-width: 800px';
$media_md2: 'max-width: 700px';
$media_sm: 'max-width: 600px';
$media_sm2: 'max-width: 500px';
$media_xs: 'max-width: 400px';