@font-face {
    font-family: 'Geist Sans';
    font-weight: 400;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-400-normal.woff) format('woff');
}

@font-face {
    font-family: 'Geist Sans';
    font-weight: 200;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-200-normal.woff) format('woff');
}

@font-face {
    font-family: 'Geist Sans';
    font-weight: 300;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-300-normal.woff) format('woff');
}

@font-face {
    font-family: 'Geist Sans';
    font-weight: 500;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-500-normal.woff) format('woff');
}

@font-face {
    font-family: 'Geist Sans';
    font-weight: 600;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-600-normal.woff) format('woff');
}

@font-face {
    font-family: 'Geist Sans';
    font-weight: 700;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-700-normal.woff) format('woff');
}

@font-face {
    font-family: 'Geist Sans';
    font-weight: 800;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-800-normal.woff) format('woff');
}


@font-face {
    font-family: 'Geist Sans';
    font-weight: 900;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/geist-sans@latest/latin-900-normal.woff) format('woff');
}
