.nfts {
    padding: 64px 0;

    .container-big {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .avaliablesoon {
        margin: 0 auto;
        margin-top: 32px;
    }

    &__title {
        text-align: center;
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
    }

    img {
        margin-top: 32px;
    }
}