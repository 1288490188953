.pannel {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__basic {}

    &__address {
        display: flex;
        align-items: center;
        gap: 8px;

        &-content {
            opacity: 0.5;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }

    &__sol {
        margin-top: 4px;
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
    }

    &__fiat {
        color: rgb(131, 131, 131);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        border-radius: 16px;

        &-part {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &-part:nth-child(1) {
            padding: 0 24px;
            padding-top: 15px;
            padding-bottom: 8px;
            border-bottom: 1px solid rgb(41, 41, 41);
            ;
        }

        &-part:nth-child(2) {
            padding: 0 24px;
            padding-top: 8px;
            padding-bottom: 15px;
        }

        &-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-row>* {
            flex-basis: 50%;
        }

        &-address {
            display: flex;
            align-items: center;
            gap: 18px;

            svg {
                cursor: pointer;
                &:active {
                    animation: bounce .1s linear;
                }
            }
        }

        &-label {
            color: #808080;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        &-content {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    &__ref {
        &-title {
            color: #808080;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
        }

        &-wrapper {
            box-sizing: border-box;
            border: 1px solid rgb(29, 29, 29);
            border-radius: 16px;
            background: rgb(15, 15, 15);
            margin-top: 8px;
            padding: 8px 16px;
            padding-right: 6px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        &-copy {
            padding: 6px 16px;
            border-radius: 8px;
            box-shadow: inset 0px -1px 0px 0px rgb(41, 41, 41), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12);
            background: rgb(29, 29, 29);

            color: white;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;

            display: flex;
            gap: 10px;
            align-items: center;

            &:active {
                svg {
                    animation: bounce .1s linear;
                }
            }
        }
    }
}