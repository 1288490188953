.avaliablesoon {
    max-width: 175px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 15px;

    border-radius: 8px;
    box-shadow: inset 0px -1px 0px 0px rgb(31, 31, 31), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgb(31, 31, 31);
    background: rgb(29, 29, 29);

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
}