* {
  font-family: "Geist Sans";
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: $black;
  color: $white;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
}
}

main {
  overflow: hidden;
  position: relative;
}

a {
  text-decoration: none;
  list-style: none;
  transition: .2s;

  &:hover {
    opacity: .5;
  }
}

a:focus {
  outline: none;
}

ul,
ol {
  list-style: none;
}

h1 {
  font-size: 65px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0%;
  color: $white;
}

h3 {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: $white;
}

button.white {
  background-color: $white;
  text-align: center;
  color: black;
  border-radius: 120px;
  border: 1px solid transparent;
  transition: .25s ease-in-out;

  &:hover {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
  }
}

button.empty,
a.empty {
  background-color: transparent;
  text-align: center;
  border-radius: 120px;
  border: 1px solid transparent;
  transition: .25s ease-in-out;

  &:hover {
    border: 1px solid $white;
    color: $white;
    opacity: 1;
  }
}

.container-small {
  padding: 0px 24px;
  max-width: 784px;
  width: 100%;
  margin: 0 auto;
}

.container {
  padding: 0px 24px;
  max-width: 1168px;
  width: 100%;
  margin: 0 auto;
}

.container-big {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

.box {
  box-sizing: border-box;
  border: 1px solid rgb(41, 41, 41);
  border-radius: 32px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgb(31, 31, 31), inset 0px -1px 0px 0px rgb(31, 31, 31), inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12);
  background: rgb(15, 15, 15);
}

