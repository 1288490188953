.wallet-connection {
    height: calc(100vh - 66px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__title {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        max-width: 300px;
    }
    button {
        margin: 20px;
    }
}