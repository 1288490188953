.navbar {
    padding: 0px 82px;
    position: relative;
    height: 66px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // align-items: center;

    border-bottom: 1px solid rgb(29, 29, 29);

    .navbar__btn-link:hover {
        opacity: 1;
    }

    &__logo-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
            translate: 0px 2px;
            width: 25px;
            height: 25px;
        }
    }

    &__logo {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    #menu-close {
        font-size: 0;
    }

    &__logo-mob {
        display: none;
    }

    &__burger {
        display: none;
        width: 24px;
        height: 24px;
        margin-top: 20px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        width: 66%;
        gap: 20px;
    }

    &__links {
        padding-top: 24px;
        display: flex;
        gap: 24px;
    }

    &__link {
        font-size: 13px;
        line-height: 18px;
        color: $gray;
    }

    &__btn {
        padding: 8px 16px;
    }

}

.wallet-panel {
    margin-top: 15px;

    cursor: pointer;
    padding: 8px 16px;

    height: 36px;

    &__main {
        display: flex;
        align-items: center;
        gap: 8px;

    }

    border-radius: 120px;
    box-shadow: inset 0px -1px 0px 0px rgb(41, 41, 41),
    inset 0px 1px 0.75px 0px rgba(255, 255, 255, 0.12),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgb(41, 41, 41);
    background: rgb(41, 41, 41);

    &__img {
        border-radius: 50%;
    }

    &__placeholder {
        margin-top: 2px;
        font-size: 13px;
    }

    &__disc {
        font-size: 0px;
        text-align: center;
    }

    &__address {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
    }

    transition: .2s, border-radius .1s;
}

.wallet-panel[data-dd=true] {
    padding: 8px;
    padding-right: 16px;
    &:hover {
        height: 55px;
        border-radius: 15px;
        .wallet-panel__disc {
            margin-top: 5px;
            font-size: 13px;
            animation: fadeIn .3s linear;
        }
    }
}

.navbar__menu_mob {
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    background-color: black;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    padding: 16px 24px;

    transition: .2s ease-in-out;

    transform: translateX(100%);

    .navbar__links {
        flex-direction: column;
        align-items: flex-end;
        padding-right: 4px;
    }

    .navbar__link {
        text-align: right;
        font-size: 16px;
    }

    button {
        margin-top: 24px;
        font-size: 18px;
    }

    #menu-close {
        font-size: 24px;
        width: 24px;
        color: $white;
    }
}