.footer {
    position: relative;
    z-index: 10;
    padding: 24px;

    background: radial-gradient(80.00% 70.00% at 50% 100%, rgba(1, 65, 214, .5), rgb(0, 0, 0) 100%);
    background-color: black;
    border-radius: 24px 24px 0px 0px;

    &__content {
        max-width: 1232px;
        width: 100%;
        margin: 0 auto;

        padding: 32px 56px;

        display: flex;
        gap: 32px;
    }

    &__content>* {
        flex-basis: 100%;
    }

    &__col:nth-child(1) {
        svg {
            width: 48px;
            height: 48px;
        }
    }

    &__title {
        margin-top: 26px;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    &__rights {
        margin-top: 48px;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
    }

    &__rights_mob {
        display: none;
        color: $gray;
    }

    &__sub {
        color: $dgray;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &__list {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__li {
        a {
            color: $white;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;

            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
}